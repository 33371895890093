<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Ticketing from "@/services/Ticketing";
    import Http from "@/services/Http";
    import Swal from "sweetalert2";
    /**
     * Starter page
     */
    export default {
        components: { Layout, PageHeader },
        page: {
            title: "Tickets Matches",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Tickets Matches",
                items: [
                    {
                        text: "Tickets Matches",
                        active: true,
                    },
                ],
                isBusy: false,

                tableData: [],
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "match_datetime",
                sortDesc: true,
                fields: [
                    {
                        key: "match_id",
                        label: "Match Id",
                    },
                    {
                        key: "home_team",
                        label: "Home",
                    },
                    {
                        key: "away_team",
                        label: "Away",
                    },
                    {
                        key: `competition_name`,
                        label: "Competition name",
                    },
                    {
                        key: "match_name",
                        label: "Match name",
                    },
                    {
                        key : "match_datetime" ,
                        label : "Date" ,
                    } ,
                    "action",

                ],
            };
        },
        middleware: "authentication",
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.tableData.length;
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;
        },
        async created() {
            this.getMatchesTickets();
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            async getMatchesTickets() {
                try {


                    var filter_string = 'match_datetime=2023-06-01';

                    this.toggleBusy();
                    const response = await Ticketing.getMatchesTickets(filter_string);
                    this.tableData = response.data.data;
                    this.toggleBusy();
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = [];
                }
            },

            refreshTable() {},

            async success() {
                this.getMatchesTickets();
            },
            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 50000,
                });
            },
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-md-6">
                                <div class="mb-3">

                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>

                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <b-table
                                :busy="isBusy"
                                table-class="table table-centered datatable table-card-list"
                                thead-tr-class="bg-transparent"
                                :items="tableData"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                show-empty
                                empty-text="No Data Found"
                                @filtered="onFiltered"
                        >
                            <template #cell(action)="data" class="d-flex align-items-center">
                                <router-link
                                        :to="'tickets_matches/tickets_match_details?match_id='+data.item.match_id+'&remote_match_id='+data.item.remote_match_id"
                                        class="px-2 text-black"
                                        v-b-tooltip.hover
                                        title="Match tickets details"
                                >
                                    <i class="uil uil-eye font-size-18"></i>

                                        <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                                        />
                                </router-link>
                            </template>

                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>

                        </b-table>

                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                    <ul class="pagination pagination-rounded">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style>
    .sm {
        width: 1rem;
        height: 1rem;
    }
</style>
